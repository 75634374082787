import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>Curs Policia Local Municipal de Mollet del Vallès (2024)</Title>
                <Text>
                    Arriba el millor curs per preparar l'accés a la policia Municipal de Mollet del
                    Vallès 2024
                    <br />
                    <br />
                    Un curs, complementari al curs ONLINE de policia local que tenim a la web ja que
                    aquest curs no conté temari.
                    <br />
                    <br />
                    Al curs trobaràs tot el contingut necessari per posicionar-te entre els millors.
                    <br />
                    <br />
                    Un curs viu i en constant evolució.
                    <br />
                    <br />
                    Dins del curs trobaràs:
                    <br />
                    <br />
                    - Cultura general bàsica
                    <br />
                    <br />
                    - Cultura general avançada
                    <br />
                    <br />
                    - Cultura general per a CRACKS
                    <br />
                    <br />
                    - Totes les notícies d'actualitat i test
                    <br />
                    <br />
                    - El Municipi en xifres
                    <br />
                    <br />
                    -Dades rellevants
                    <br />
                    <br />
                    - 4 exàmens oficials
                    <br />
                    <br />
                    I Simulacres on et posarem a prova!!
                    <br />
                    <br />
                    Per només 34,99 euros.
                    <br />
                    <br />
                    El curs estarà obert fins al dia de l'examen
                    <br />
                    <br />
                    Entra ara i avança al teu ritme!
                </Text>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/84U5T82_PZs"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>*/}
            </div>
        </ProductTemplate>
    );
};
export default Product;
